<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Veranstalter bearbeiten">
      <support-organization-form
        :loading="loading"
        :organization="organization"
        :form-submitted="saveSupportOrganization"
        mode="edit"
      ></support-organization-form>
    </page-content>
    <portal to="fixed-footer-right">
      <el-button-group>
        <el-button
          v-if="
            organization.status === 'submitted' ||
              organization.status === 'rejected'
          "
          icon="fal fa-thumbs-up"
          size="small"
          @click="approve"
        >
          Freischalten
        </el-button>
        <el-button
          v-if="
            organization.status === 'submitted' ||
              organization.status === 'approved'
          "
          icon="fal fa-thumbs-down"
          size="small"
          @click="reject"
        >
          Ablehnen
        </el-button>
      </el-button-group>
    </portal>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import SupportOrganizationForm from "@/components/forms/SupportOrganizationForm"
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Veranstalter bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    SupportOrganizationForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      organization: {}
    }
  },
  async created() {
    try {
      this.organization = await SupportOrganizationsRepository.get(
        this.$route.params.id
      )
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveSupportOrganization(organization) {
      await SupportOrganizationsRepository.update(organization.id, organization)
      this.$router.push({ name: "SupportOrganizationsPage" })
    },
    async reject() {
      try {
        await SupportOrganizationsRepository.reject(this.$route.params.id)
        this.$message({
          message: "Veranstalter wurde abgelehnt.",
          type: "success",
          showClose: true
        })
        this.$router.push({ name: "SupportOrganizationsPage" })
      } catch (error) {
        this.handleApiError(error)
      }
    },
    async approve() {
      try {
        await SupportOrganizationsRepository.approve(this.$route.params.id)
        this.$message({
          message: "Veranstalter wurde freigeschaltet.",
          type: "success",
          showClose: true
        })
        this.$router.push({ name: "SupportOrganizationsPage" })
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }
}
</script>
